const INVOICE_STATUS = [
  { id: 0, name: 'New', color: 'red' },
  { id: 1, name: 'Transit', color: 'orange' },
  { id: 2, name: 'Finished', color: 'green' }
]

const INVOICE_TYPES = [
  { id: 1, name: 'ACCOUNT' },
  { id: 2, name: 'MOVEMENT' }
]

const USER_ROLES = [
  { id: 1, name: 'ADMIN' },
  { id: 2, name: 'MANAGER' },
  { id: 3, name: 'SENIORMANAGER' },
  { id: 4, name: 'SELLER' },
  { id: 5, name: 'GUEST'},
]

const DISCOUNT_PROCENTS = [
  { id: 3, name: '3%' },
  { id: 5, name: '5%' },
  { id: 10, name: '10%' },
  { id: 15, name: '15%' },
  { id: 20, name: '20%' },
  { id: 25, name: '25%' },
  { id: 30, name: '30%' },
  { id: 40, name: '40%' },
  { id: 50, name: '50%' },
  { id: 60, name: '60%' },
]

export default {
  install (Vue, opt) {
    Vue.prototype.$INVOICE_STATUS = INVOICE_STATUS
    Vue.prototype.$INVOICE_TYPES = INVOICE_TYPES
    Vue.prototype.$USER_ROLES = USER_ROLES
    Vue.prototype.$DISCOUNT_PROCENTS = DISCOUNT_PROCENTS
  }
}
